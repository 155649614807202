import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/iconfont/iconfont.css';
import '@/assets/iconfont/iconfont.js';
import '@/assets/iconfont/icon.css';
import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  router,
  pinia,
  store,
  render: (h) => h(App)
}).$mount('#app');
